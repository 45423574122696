import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as XLSX from 'xlsx';

import { GridApi } from 'ag-grid-community';
import {
  Button,
  InputAdornment,
  Switch,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
  Typography,
  Box,
  IconButton,
  Badge,
  Checkbox,
  Autocomplete,
  DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import debounce from 'lodash/debounce';
import {
  addUserAdmin,
  getUsersBadge,
  updateUserAdmin,
  updateUserHelpTeam,
  updateUserLockedStatus,
  updateUserStatus,
  updateUserVerifiedUser,
  getRejectedUsersBadge,
  resendUserMail,
} from '../../reduxStore/reducer/userReducer';
import { ICellRendererParams } from 'ag-grid-community';
import { BsSearch } from 'react-icons/bs';
import { Formik, Form, Field } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import { ServerSideGrid } from '../../utils/MasterGrid/ServerSideGrid';
import GridHeader2 from '../../utils/gridHeader/GridHeader2';
import { ColumnApi } from 'ag-grid-community';
import { getUserById } from '../../reduxStore/reducer/registerReducer';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getTaspaAssessmentDetail } from '../../reduxStore/reducer/assessmentReducer';
import { Scrollbar } from 'react-scrollbars-custom';
import Alert from '../../utils/Alert/Alert';
import {
  getPendingApprovalsSheetDownload,
  getPendingInvitesSheetDownload,
  getStudentListSheetDownload,
  getRejectUsersSheetDownload,
} from '../../reduxStore/reducer/usersInformationSheetReducer';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Users = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [rejectedUserSearchQuery, setRejectedUserSearchQuery] = useState('');
  const [studentSearchQuery, setStudentSearchQuery] = useState('');

  //Delays capturing, changing text on text field
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridApi1, setGridApi1] = useState<GridApi>();
  const [gridApiStudent, setGridApiStudent] = useState<GridApi>();

  const [gridApi2, setGridApi2] = useState<GridApi>();
  const [rejectedUserGridApi, setRejectedUserGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [columnApi1, setColumnApi1] = useState<ColumnApi>();
  const [columnApi2, setColumnApi2] = useState<ColumnApi>();
  const [rejectUserColumnApi, setRejectUserColumnApi] = useState<ColumnApi>();
  const [studentColumnApi, setstudentColumnApi] = useState<ColumnApi>();

  const dispatch = useDispatch<AppDispatch>();

  const pendingUserData = useSelector((state: any) => state.users.badgeData);
  const rejectUserData = useSelector((state: any) => state.users);

  useEffect(() => {
    dispatch(getUsersBadge());
    dispatch(getRejectedUsersBadge());
  }, [dispatch]);

  function Actions(row: any) {
    return (
      <div className=''>
        {/* {getUserDataAndType?.data?.verified_taspa_user === false && ( */}
        <IconButton
          disabled={
            row.data.id === getUserDataAndType.data.id ||
            getUserDataAndType?.data?.verified_taspa_user === true
          }
          onClick={() => {
            row.setIsEdit(true);
            row.handleUpdate(row.data);
          }}
        >
          <EditIcon
            className={`float-right ${
              row.data.id === getUserDataAndType.data.id ||
              getUserDataAndType?.data?.verified_taspa_user === true
                ? ''
                : 'text-blue-600'
            }`}
          />
        </IconButton>

        {/* )} */}
      </div>
    );
  }

  // const handleUpdate = (data: any) => {
  //   setInitialValue({ ...data });
  //   setOpen(true);
  // };

  //
  const [taspaParams, setTaspaParams] = useState<any>({});

  const handleUpdate = (data: any) => {
    // Extract assessment ids from taspauserassessments array
    const taspaUserAssessmentIds = data.taspauserassessments || [];

    // Filter TaspaassessmentDetails to get the default selected assessments
    const defaultSelectedAssessments =
      TaspaassessmentDetails?.record.filter((assessment: any) =>
        taspaUserAssessmentIds.includes(assessment.assessment_id)
      ) || [];

    // Set default selected assessments in state
    setDefaultSelectedAssessments(defaultSelectedAssessments);
    const uniqueAssessmentIds = [...new Set(data.taspauserassessments)]; // Get unique assessment IDs
    const isTaspaUser = uniqueAssessmentIds.length > 0; // Check if there are any unique assessment IDs

    setInitialValue({
      ...data,
      isAccountVerified: data.verified_user || false,
      isTaspaUser: isTaspaUser,
    });
    setOpen(true);
    setTaspaParams({
      ...data,
    });
  };

  const transactionDefs: any = [
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      // resizable: false,
    },

    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      // resizable: false,
    },

    {
      headerName: 'User Type',
      field: 'type',
      sortable: true,
      filter: 'agTextColumnFilter',
      // resizable: false,
    },

    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filter: 'agTextColumnFilter',
      // resizable: false,
    },
    {
      headerName: 'Locked',
      sortable: false,
      filter: false,
      // resizable: false,
      cellClassRules: {
        'api-call-in-progress': (params: any) => params.data.apiCallInProgress,
      },
      field: 'is_locked',
      cellRenderer: (param: any) => {
        return (
          <Switch
            type='checkbox'
            checked={param?.data?.is_locked || false}
            disabled={
              param?.data?.id === getUserDataAndType.data.id ||
              getUserDataAndType?.data?.verified_taspa_user === true
            }
            onChange={(e: any) => {
              dispatch(
                updateUserLockedStatus(e.target.checked, param.data, gridApi)
              );
            }}
          />
        );
      },
    },
    {
      headerName: 'Verifier',
      sortable: false,
      filter: false,
      // resizable: false,
      cellClassRules: {
        'api-call-in-progress': (params: any) => params.data.apiCallInProgress,
      },

      field: 'verified_user',

      cellRenderer: (param: any) => {
        return (
          <Switch
            type='checkbox'
            checked={param?.data?.verified_user || false}
            disabled={
              param?.data?.id === getUserDataAndType.data.id ||
              getUserDataAndType?.data?.verified_taspa_user === true
            }
            onChange={(e: any) => {
              dispatch(
                updateUserVerifiedUser(e.target.checked, param.data, gridApi)
              );
            }}
          />
        );
      },
    },
    // {
    //   headerName: 'TASPA',
    //   sortable: false,
    //   filter: false,
    //   cellClassRules: {
    //     'api-call-in-progress': (params: any) => params.data.apiCallInProgress,
    //   },
    //   cellRenderer: (param: any) => {
    //     const isTaspaUser = param?.data?.taspauserassessments?.length > 0; // Check if taspauserassessments array is not empty
    //     return (
    //       <Switch
    //         type="checkbox"
    //         checked={isTaspaUser}
    //         onChange={(e: any) => {
    //           dispatch(updateTaspaUser(e.target.checked, param.data, gridApi));
    //         }}
    //       />
    //     );
    //   },
    // },
    // {
    //   headerName: 'Help Team',
    //   sortable: false,
    //   filter: false,
    //   field: 'help_team',
    //   cellClassRules: {
    //     'api-call-in-progress': (params: any) => params.data.apiCallInProgress,
    //   },
    //   cellRenderer: (param: any) => {
    //     return (
    //       <Switch
    //         type='checkbox'
    //         checked={param?.data?.help_team || false}
    //         disabled={
    //           param?.data?.type === 'faculty' ||
    //           param.data.id === getUserDataAndType.data.id ||
    //           getUserDataAndType?.data?.verified_taspa_user === true
    //         }
    //         onChange={(e: any) => {
    //           dispatch(
    //             updateUserHelpTeam(e.target.checked, param.data, gridApi)
    //           );
    //         }}
    //       />
    //     );
    //   },
    // },
    // {
    //   headerName: 'TASPA User',
    //   sortable: false,
    //   filter: false,
    //   field: 'verified_taspa_user',
    //   cellClassRules: {
    //     'api-call-in-progress': (params: any) => params.data.apiCallInProgress,
    //   },
    //   cellRenderer: (param: any) => {
    //     const yesOrNo = param?.data?.verified_taspa_user ? 'Yes' : 'No';
    //     return <span>{yesOrNo}</span>;
    //   },
    // },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      // resizable: false,

      filter: false,
      minWidth: 130,
      maxWidth: 140,
      cellRenderer: Actions,
      cellRendererParams: (params: ICellRendererParams) => ({
        ...params,
        setIsEdit,
        handleUpdate,
      }),
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      cellRenderer: (row: any) => {
        return moment(moment(row.data.created_at).utc())
          .local()
          .format('MM-DD-YYYY hh:mm a');
      },
    },
  ];
  const transactionUsersByOrganizationDefs = [
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Email',
      field: 'organization_email_id',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Organization',
      field: 'organization_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Activation Status',
      field: 'status',
      sortable: false,
      cellRenderer: (param: any) => {
        return (
          <div>
            <div>
              {param?.data?.status || param?.data?.status == true ? (
                <b>Approved</b>
              ) : param?.data?.status == false ? (
                <b>Rejected</b>
              ) : (
                <b>Pending</b>
              )}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Actions',
      field: 'status',
      sortable: false,
      cellRenderer: (param: any) => {
        return (
          <div>
            <Button
              color='primary'
              disabled={param?.data?.status}
              variant='contained'
              size='small'
              style={{
                fontSize: '12px',
                height: '29px',
                alignSelf: 'center',
                marginRight: '5px',
              }}
              onClick={() => {
                dispatch(updateUserStatus(true, param.data, gridApi, gridApi1));
              }}
            >
              Approve
            </Button>
            <Button
              color='error'
              variant='contained'
              disabled={param?.data?.status == false}
              size='small'
              style={{
                fontSize: '12px',
                height: '29px',
                alignSelf: 'center',
              }}
              onClick={() => {
                dispatch(
                  updateUserStatus(false, param.data, gridApi, gridApi1)
                );
              }}
            >
              Reject
            </Button>
          </div>
          // <Switch
          //   type="checkbox"
          //   checked={param?.data?.status || false}
          //   onChange={(e: any) => {
          //     dispatch(
          //       updateUserStatus(
          //         e.target.checked,
          //         param.data,
          //         gridApi,
          //         gridApi1
          //       )
          //     );
          //   }}
          // />
        );
      },
    },
  ];
  const transactionUserInviteDefs: any = [
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Actions',
      cellRenderer: (param: any) => {
        return (
          <div>
            <Button
              color='primary'
              variant='contained'
              size='small'
              style={{
                fontSize: '12px',
                height: '29px',
                alignSelf: 'center',
                marginRight: '5px',
                textTransform: 'initial',
              }}
              onClick={() => {
                dispatch(resendUserMail(param.data));
              }}
            >
              Resend Email
            </Button>
          </div>
        );
      },
    },

    {
      headerName: 'Organization Email',
      field: 'organization_email_id',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Organization',
      field: 'organization_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      cellRenderer: (row: any) => {
        return moment(moment(row.data.created_at).utc())
          .local()
          .format('MM-DD-YYYY hh:mm a');
      },
    },
  ];
  const transactionRejectedUsersDefs = [
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Organization Email',
      field: 'organization_email_id',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Organization',
      field: 'organization_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Activation Status',
      field: 'status',
      sortable: false,
      cellRenderer: (param: any) => {
        return (
          <div>
            <div>
              {param?.data?.status || param?.data?.status == true ? (
                <b>Approved</b>
              ) : param?.data?.status == false ? (
                <b>Rejected</b>
              ) : (
                <b>Pending</b>
              )}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Actions',
      field: 'status',
      sortable: false,
      cellRenderer: (param: any) => {
        return (
          <div>
            <Button
              color='primary'
              disabled={param?.data?.status}
              variant='contained'
              size='small'
              style={{
                fontSize: '12px',
                height: '29px',
                alignSelf: 'center',
                marginRight: '5px',
              }}
              onClick={() => {
                dispatch(
                  updateUserStatus(
                    true,
                    param.data,
                    '',
                    '',
                    'rejectGrid',
                    rejectedUserGridApi
                  )
                );
              }}
            >
              Approve
            </Button>
          </div>
        );
      },
    },
  ];
  const transactionStudentDefs = [
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: 'agTextColumnFilter',
    },

    {
      headerName: 'Email',
      field: 'email_id',
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      cellRenderer: (row: any) => {
        return moment(moment(row.data.created_at).utc())
          .local()
          .format('MM-DD-YYYY hh:mm a');
      },
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [defaultSelectedAssessments, setDefaultSelectedAssessments] = useState(
    []
  );
  const [isTyping, setIsTyping] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClickClose = () => {
    Alert.confirm(() => {
      setOpen(false);
      setDefaultSelectedAssessments([]);
      setIsTyping(false);
    });
  };

  // const [isAccountVerified, setIsAccountVerified] = useState(false);

  const [initialValues, setInitialValue] = useState<any>({
    id: '',
    first_name: null,
    middle_name: '',
    last_name: '',
    email: null,
    isAccountVerified: false,
    isTaspaUser: false,
    selectedOptions: [],
  });

  const handleOpenForm = () => {
    setOpen(true);
    setIsEdit(false);
    setInitialValue({
      id: '',
      first_name: null,
      middle_name: '',
      last_name: '',
      email: null,
      isAccountVerified: false,
      isTaspaUser: false,
      selectedOptions: [],
    });
  };

  const [isEdit, setIsEdit] = useState(false);

  const validation = Yup.object({
    first_name: Yup.string()
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    middle_name: Yup.string()
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .nullable(),
    last_name: Yup.string()
      .matches(/^[^ ]/, 'The starting letter should not be a space.')
      .matches(/[^\s]$/, 'Ending letter should not be a space.')
      .required('Required Field'),
    email: Yup.string()
      .email('Email must be a valid email.')
      .nullable()

      .required('Entered text is not a valid email.'),
    isAccountVerified: Yup.boolean(),
    // isTaspaUser: Yup.boolean(),
    // selectedOptions: Yup.array().when('isTaspaUser', {
    //   is: true,
    //   then: Yup.array()
    //     .min(1, 'At least one option is required')
    //     .required('At least one option is required'),
    //   otherwise: Yup.array(),
    // }),
  });

  const debounceSearch = debounce((value: string) => {
    setDebouncedSearchQuery(value);
  }, 500);
  const debounceSearch1 = debounce((value: string) => {
    setDebouncedSearchQuery(value);
  }, 500);
  const debounceSearch2 = debounce((value: string) => {
    setDebouncedSearchQuery(value);
  }, 500);
  const debounceSearch3 = debounce((value: string) => {
    setDebouncedSearchQuery(value);
  }, 500);

  const onFilterTextBoxChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(e.target.value);
    const value = e.target.value;
    debounceSearch(value);
  };

  const onFilterTextBoxChanged2 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery1(e.target.value);
    const value = e.target.value;
    debounceSearch1(value);
  };

  const onFilterTextBoxChanged3 = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery2(e.target.value);
    const value = e.target.value;
    debounceSearch2(value);
  };
  const onFilterTextBoxRejectUser = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRejectedUserSearchQuery(e.target.value);
    const value = e.target.value;
    debounceSearch2(value);
  };

  const onFilterTextBoxStudent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setStudentSearchQuery(e.target.value);
    const value = e.target.value;
    debounceSearch3(value);
  };

  const textFieldProps = {
    value: searchQuery,
    onChange: onFilterTextBoxChanged,
  };
  const buttonProps = {
    children: 'Add Admin User',
    onClick: handleOpenForm,
    sm: 'Add',
  };

  const getUserDataAndType = useSelector(
    (state: any) => state.register.getUserById
  );

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(
      getUserById({
        id: `${JSON.parse(localStorage.getItem('token') || '{}')}`,
      })
    );

    if (getUserDataAndType?.data?.type === 'faculty') {
      setValue(1);
    }
    // pass the `data` object as an argument
    // dispatch(getSubDisciplineAction());
  }, [dispatch, getUserDataAndType?.data?.type]);

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  useEffect(() => {
    // Fetch TaspaassessmentDetails when the component mounts
    dispatch(getTaspaAssessmentDetail());
  }, [dispatch]);

  const TaspaassessmentDetails: any = useSelector(
    (state: RootState) => state.assessment.TaspaassessmentDetails
  );
  console.log(TaspaassessmentDetails, '');

  const [taspaOpen, setTaspaOpen] = useState(false); // State to manage dialog open/close

  const handleTaspaButtonClick = () => {
    setTaspaOpen(true); // Open the dialog when Taspa User button is clicked
  };

  const handleTaspaClose = () => {
    setTaspaOpen(false); // Close the dialog
  };

  // useEffect(() => {
  //   if (gridApi)
  // }, [gridApi]);
  const [studentListSheet, setStudentListSheet] = useState<any[] | null>(null);
  const [pendingInvitesSheet, SetPendingInvitesSheet] = useState<any[] | null>(
    null
  );
  const [pendingApprovalsSheet, SetPendingApprovalsSheet] = useState<
    any[] | null
  >(null);
  const [rejectUsersSheet, SetRejectUsersSheet] = useState<any[] | null>(null);

  useEffect(() => {
    dispatch(getStudentListSheetDownload(setStudentListSheet));
    dispatch(getPendingInvitesSheetDownload(SetPendingInvitesSheet));
    dispatch(getPendingApprovalsSheetDownload(SetPendingApprovalsSheet));
    dispatch(getRejectUsersSheetDownload(SetRejectUsersSheet));
  }, [dispatch]);
  const handleDownloadData = () => {
    let data: Array<Record<string, any>> = [];
    let sheetName = '';

    // Determine which grid to export based on the active tab
    switch (value) {
      case 0:
        gridApi?.forEachNode((node) => data.push(node.data));
        sheetName = 'Users Data';
        break;
      case 1:
        gridApi1?.forEachNode((node) => data.push(node.data));
        sheetName = 'Pending Approvals Data';
        break;
      case 2:
        gridApi2?.forEachNode((node) => data.push(node.data));
        sheetName = 'Pending Invites Data';
        break;
      case 3:
        rejectedUserGridApi?.forEachNode((node) => data.push(node.data));
        sheetName = 'Rejected Users Data';
        break;
      case 4:
        gridApiStudent?.forEachNode((node) => data.push(node.data));
        sheetName = 'Student List Data';
        break;
      default:
        break;
    }

    if (data.length === 0) {
      alert('No data to download');
      return;
    }

    // Apply transformation only for gridApi (value === 0)
    let transformedData = data;
    const organizationTypeMapping: Record<
      '1' | '2' | '3' | '4' | '5' | '6' | '7',
      string
    > = {
      '1': 'Institution of Higher Education',
      '2': 'Government agency',
      '3': 'K-12 School',
      '4': 'Other non-profit organization',
      '5': 'Professional society',
      '6': 'For-profit enterprise',
      '7': 'Other',
    };
    if (value === 0) {
      transformedData = data.map((row) => ({
        'First Name': row.first_name,
        'Last Name': row.last_name,
        Email: row.email,
        'Is Disabled':
          row.is_disabled === true
            ? 'Yes'
            : row.is_disabled === false
            ? 'No'
            : '-',
        'Is Locked':
          row.is_locked === true ? 'Yes' : row.is_locked === false ? 'No' : '-',
        'Verified User':
          row.verified_user === true
            ? 'Yes'
            : row.verified_user === false
            ? 'No'
            : '-',

        'Help Team': row.help_team || '-',
        'Organization Name':
          row.orgs?.map((org: any) => org.name).join(', ') || '-',
        'Organization Type':
          row.orgs
            ?.map(
              (org: { type: keyof typeof organizationTypeMapping }) =>
                organizationTypeMapping[org.type] || '-'
            )
            .join(', ') || '-',
        'Created At': moment(row.created_at)
          .utc()
          .local()
          .format('MM-DD-YYYY hh:mm a'),
      }));
    } else if (value === 1) {
      // Transform data for gridApi1
      transformedData =
        pendingApprovalsSheet?.map((row) => ({
          'First Name': row.first_name,
          'Last Name': row.last_name,
          'Organization Name': row.organization_name,
          'Activation Status': row.status || 'Pending',
        })) || [];
    } else if (value === 2) {
      // Transform data for gridApi2
      transformedData =
        pendingInvitesSheet?.map((row) => ({
          'First Name': row.first_name,
          'Last Name': row.last_name,
          'Organization Name': row.organization_name,
          'Organization Email': row.organization_email_id,
          'Created At': moment(row.created_at)
            .utc()
            .local()
            .format('MM-DD-YYYY hh:mm a'),
        })) || [];
    } else if (value === 3) {
      // Transform data for gridApi3 (Rejected Users)
      transformedData =
        rejectUsersSheet?.map((row) => ({
          'First Name': row.first_name,
          'Last Name': row.last_name,
          'Organization Name': row.organization_name,
          'Organization Email': row.organization_email_id,
          Status: row.status === false ? 'Rejected' : row.status,
        })) || [];
    } else if (value === 4) {
      // Use studentListSheet instead of gridApiStudent for Student List
      transformedData =
        studentListSheet?.map((row) => ({
          'First Name': row.first_name?.trim() || '-',
          'Last Name': row.last_name?.trim() || '-',
          Email: row.email_id || '-',
          'Created At': moment(row.created_at)
            .utc()
            .local()
            .format('MM-DD-YYYY hh:mm a'),
          'Assessment Given': row.overall_assessment_count || '0',
          'Assessment Taken': row.taken_count || '0',
          'Assessment Not Taken': row.not_taken_count || '0',
        })) || [];
    }

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(transformedData);

    // Auto-size columns based on content
    const header = Object.keys(transformedData[0]);
    const colWidths = header.map((col) => ({
      wch: Math.max(20, col.length + 2), // Minimum width of 20 characters
    }));

    // Add this auto-size information to the worksheet
    worksheet['!cols'] = colWidths;

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Write the file and download it
    XLSX.writeFile(workbook, `${sheetName}.xlsx`);
  };

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={handleDownloadData}
            sx={{ position: 'relative', top: '6rem', left: '26px' }}
          >
            Download User List
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            {getUserDataAndType?.data?.type === 'admin' && (
              <Tab label='Users' {...a11yProps(0)} />
            )}

            <Tab
              disabled={getUserDataAndType?.data?.type === 'faculty'}
              label={
                <Badge
                  badgeContent={
                    pendingUserData.length ? pendingUserData.length : 0
                  }
                  color='error'
                >
                  Pending Approvals
                </Badge>
              }
              {...a11yProps(1)}
            />
            {getUserDataAndType?.data?.type === 'admin' && (
              <Tab label='Pending Invites' {...a11yProps(2)} />
            )}
            {getUserDataAndType?.data?.type === 'admin' && (
              <Tab
                label={
                  <Badge
                    badgeContent={
                      rejectUserData?.rejectUserData.length
                        ? rejectUserData?.rejectUserData[0].count
                        : 0
                    }
                    color='error'
                  >
                    Reject Users
                  </Badge>
                }
                {...a11yProps(3)}
              />
            )}
            {getUserDataAndType?.data?.type === 'admin' && (
              <Tab label='Student List' {...a11yProps(4)} />
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GridHeader2
            textFieldProps={textFieldProps}
            buttonProps={buttonProps}
          />
          <ServerSideGrid
            rowDataUrl='/users/'
            debouncedSearchQuery={searchQuery}
            columnDefs={transactionDefs}
            gridApi={gridApi}
            columnApi={columnApi}
            setGridApi={setGridApi}
            setColumnApi={setColumnApi}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className='box-border mt-8 mr-4 shadow-2xl pt-2'>
            <div className='text-end pr-5 flex gap-x-16 justify-end items-center'>
              <TextField
                size='small'
                id='search-field'
                placeholder='Search'
                onChange={(e) => onFilterTextBoxChanged2(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BsSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <ServerSideGrid
              rowDataUrl='/users/organization'
              debouncedSearchQuery={searchQuery1}
              columnDefs={transactionUsersByOrganizationDefs}
              gridApi={gridApi1}
              columnApi={columnApi1}
              setGridApi={setGridApi1}
              setColumnApi={setColumnApi1}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className='box-border mt-8 mr-4 shadow-2xl pt-2'>
            <div className='text-end pr-5 flex gap-x-16 justify-end items-center'>
              <TextField
                size='small'
                id='search-field'
                placeholder='Search'
                onChange={(e) => onFilterTextBoxChanged3(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BsSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <ServerSideGrid
              rowDataUrl='/users/invite'
              debouncedSearchQuery={searchQuery2}
              columnDefs={transactionUserInviteDefs}
              gridApi={gridApi2}
              columnApi={columnApi2}
              setGridApi={setGridApi2}
              setColumnApi={setColumnApi2}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className='box-border mt-8 mr-4 shadow-2xl pt-2'>
            <div className='text-end pr-5 flex gap-x-16 justify-end items-center'>
              <TextField
                size='small'
                id='search-field'
                placeholder='Search'
                onChange={(e) => onFilterTextBoxRejectUser(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BsSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <ServerSideGrid
              rowDataUrl='/users/reject'
              debouncedSearchQuery={rejectedUserSearchQuery}
              columnDefs={transactionRejectedUsersDefs}
              gridApi={rejectedUserGridApi}
              columnApi={rejectUserColumnApi}
              setGridApi={setRejectedUserGridApi}
              setColumnApi={setRejectUserColumnApi}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className='box-border mt-8 mr-4 shadow-2xl pt-2'>
            <div className='text-end pr-5 flex gap-x-16 justify-end items-center'>
              <TextField
                size='small'
                id='search-field'
                placeholder='Search'
                onChange={(e) => onFilterTextBoxStudent(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BsSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <ServerSideGrid
              rowDataUrl='/users/student'
              debouncedSearchQuery={studentSearchQuery}
              columnDefs={transactionStudentDefs}
              gridApi={gridApiStudent}
              columnApi={studentColumnApi}
              setGridApi={setGridApiStudent}
              setColumnApi={setstudentColumnApi}
            />
          </div>
        </TabPanel>
      </Box>

      <Dialog open={open}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>{isEdit ? 'Update User Profile' : 'Add User Profile'}</div>
            <div>
              <CloseIcon onClick={handleClickClose} />
            </div>
          </div>
        </DialogTitle>

        <Formik
          initialValues={{
            ...initialValues,
            selectedOptions: defaultSelectedAssessments, // Set selectedOptions to defaultSelectedAssessments
          }}
          validationSchema={validation}
          onSubmit={(values) => {
            const data = {
              ...values,
              // selectedOptions: selectedOptions,
            };

            if (!isEdit) {
              dispatch(addUserAdmin(data, gridApi, setOpen));
            } else {
              dispatch(updateUserAdmin(data, gridApi, setOpen));
            }
            setIsTyping(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            isSubmitting,
            setFieldValue,
            handleBlur,
            handleChange,
          }) => (
            <DialogContent
              sx={{ width: '600px', height: 'fitContent', overflowX: 'hidden' }}
            >
              <Form>
                <Field
                  as={TextField}
                  label='First Name'
                  variant='standard'
                  required
                  name='first_name'
                  value={values.first_name}
                  helperText={
                    errors.first_name && touched.first_name
                      ? errors.first_name
                      : ''
                  }
                  fullWidth
                  error={errors.first_name && touched.first_name}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('first_name', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.first_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.first_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant='standard'
                  as={TextField}
                  label='Middle Name'
                  name='middle_name'
                  value={values.middle_name}
                  helperText={
                    errors.middle_name && touched.middle_name
                      ? errors.middle_name
                      : ''
                  }
                  fullWidth
                  error={errors.middle_name && touched.middle_name}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('middle_name', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.middle_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.middle_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant='standard'
                  as={TextField}
                  label='Last Name'
                  required
                  name='last_name'
                  value={values.last_name}
                  helperText={
                    errors.last_name && touched.last_name
                      ? errors.last_name
                      : ''
                  }
                  fullWidth
                  error={errors.last_name && touched.last_name}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('last_name', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.last_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.last_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  variant='standard'
                  as={TextField}
                  label='Email'
                  required
                  name='email'
                  value={values.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                  fullWidth
                  error={errors.email && touched.email}
                  sx={{ marginBottom: '15px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('email', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.email ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.email && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <div className='flex items-center'>
                  <label>
                    <Field
                      type='checkbox'
                      name='isAccountVerified'
                      checked={values?.isAccountVerified}
                      className='mr-3'
                    />
                    <span>Account Verification</span>
                  </label>
                </div>

                <Dialog
                  sx={{
                    '& .MuiDialog-container': {
                      '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '620px', // Set your width here
                      },
                    },
                  }}
                  open={taspaOpen}
                  onClose={handleTaspaClose}
                >
                  <DialogTitle
                    sx={{ backgroundColor: '#1663ab', color: 'white' }}
                  >
                    <div className='flex justify-between items-center'>
                      <div>Assessments</div>
                      <div>
                        <CloseIcon onClick={handleTaspaClose} />
                      </div>
                    </div>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      maxHeight: '270px',
                      // height: 'fitContent',
                      overflowX: 'hidden',
                    }}
                  >
                    <Autocomplete
                      className='mb-5'
                      multiple
                      id='assessment-autocomplete'
                      options={TaspaassessmentDetails?.record || []}
                      disableCloseOnSelect
                      // limitTags={5}
                      getOptionLabel={(option) =>
                        `${option.official_name} - ${option.code}` +
                        (option.under_development ? '- Under Development' : '')
                      }
                      open
                      value={values.selectedOptions}
                      style={{ width: '500px' }}
                      onChange={(event, newValue) => {
                        setFieldValue('selectedOptions', newValue);
                      }}
                      ListboxProps={{ style: { height: 170 } }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {`${option.official_name} - ${option.code}`}
                        </li>
                      )}
                      renderInput={(params) => (
                        <Scrollbar style={{ height: 250 }}>
                          <TextField
                            {...params}
                            variant='standard'
                            label='Select Assessment'
                            placeholder='Assessment'
                          />
                        </Scrollbar>
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button variant='contained' onClick={handleTaspaClose}>
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
                <div className='flex gap-3 justify-end mt-5'>
                  {values?.type === 'faculty' && (
                    <Button
                      variant='contained'
                      sx={{ textTransform: 'capitalize' }}
                      onClick={handleTaspaButtonClick}
                    >
                      Edit Assessment Privileges
                    </Button>
                  )}
                  <Button
                    variant='contained'
                    onClick={handleClickClose}
                    style={{ textTransform: 'capitalize' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={isSubmitting || !isValid}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {isEdit ? 'Update' : 'Save'}
                  </Button>
                </div>
                {/* <br></br>
                <br></br>
                values
                <pre>{JSON.stringify(values, null, 2)}</pre>
                errors
                <pre>{JSON.stringify(errors, null, 2)}</pre>
                touched
                <pre>{JSON.stringify(touched, null, 2)}</pre> */}
              </Form>
            </DialogContent>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default Users;
