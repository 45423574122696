import {
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fab,
  Radio,
  Checkbox,
} from '@mui/material';
import { FILE } from 'dns';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
  addAssessmentData,
  editCourseAssessmentData,
  getAssessmentDetail,
} from '../../reduxStore/reducer/assessmentReducer';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import { correctBeforeEditValues } from './AddEditCourse';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
// import api from "./index";
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';
import { useState, useMemo, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import ViewAssessment from '../Assessment/ViewAssessment';
import api from '../../reduxStore/route/index';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SunEditor from 'suneditor-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AssessSpecificQuestion from '../Assessment/AssessSpecificQuestion';
import CourseAssessSpecificQuestion from './CourseAssessSpecificQuestion';
import Alert from '../../utils/Alert/Alert';

function AddCourseAssessment({
  isEdit,
  setIsEdit,
  openAssessment,
  setOpenAssessment,
  assessmentInitialValues,
  params,
  getAssessmentByCourse,
  scroll,
  initialValues,
  setInitialValue,
  gridApi,
}: any) {
  const [openAssessSpecQue, setOpenAssessSpecQue] = useState(false);
  const [assessSpecQuestion, setAssessSpecQuestion] = useState<any>([]);
  const handleSetAssessSpecQuestion = (data: any) => {
    setAssessSpecQuestion(data);
  };

  const handleOpenAssessQue = () => {
    setOpenAssessSpecQue(true);
  };
  const handleCloseAssessQue = () => {
    setOpenAssessSpecQue(false);
  };
  const handleSaveAssessSpecQue = (question: any) => {
    setAssessSpecQuestion(question);
  };

  const dispatch = useDispatch<AppDispatch>();

  const handleClickClose = (dirty: boolean) => {
    if (dirty) {
      Alert.confirm(() => {
        setOpenAssessment(false);
        setInitialValue({
          id: '',
          assessment_id: {
            official_name: '',
            assessment_id: '',
            code: '',
          },
          no_of_administrations: 2,
          created_by: '',
        });
        setIsEdit(false);
      });
    } else {
      setOpenAssessment(false);
    }
  };

  useEffect(() => {
    dispatch(getAssessmentDetail());
  }, [assessmentInitialValues]);

  //@ts-ignore
  const assessmentDetails: any = useSelector(
    (state: RootState) => state.assessment.assessmentDetails
  );

  const validation = Yup.object({
    no_of_administrations: Yup.number().required('Required Field'),
  });
  const [assessmentSelect, setAssessmentSelect] = useState('');
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState();
  const [isDispatching, setIsDispatching] = useState(false);

  const handleClickView = async (data: any) => {
    window.open(`/viewassessment?id=${data.assessment_id}&type=course`);
  };
  const listboxRef = useRef<HTMLDivElement | null>(null); // Specify the correct type
  const labelHeight = 16; // Assuming the height of the "Select an assessment" label is 30px
  useEffect(() => {
    if (listboxRef.current) {
      // Calculate available space between label and bottom of the screen
      const windowHeight = window.innerHeight;
      const labelPosition = listboxRef.current.getBoundingClientRect().top;
      const availableSpace = windowHeight - labelPosition - labelHeight;

      // Set the maxHeight of the ListboxProps to the available space or 227px (whichever is smaller)
      listboxRef.current.style.maxHeight = `${Math.min(availableSpace, 227)}px`;
    }
  }, []);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              // width: "100%",
              maxWidth: '1000px',
              height: '100%', // Set your width here
            },
          },
        }}
        open={openAssessment}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={(values) => {
            setIsDispatching(true);

            values.course_id = assessmentInitialValues?.id;
            values.instructors = assessmentInitialValues?.instructors;
            values.assessment_coordinators =
              assessmentInitialValues?.assessment_coordinators;

            //
            if (values.assessment_id.code) {
              if (isEdit) {
                //
                //

                dispatch(
                  editCourseAssessmentData(
                    values,
                    setOpenAssessment,
                    getAssessmentByCourse,
                    params,
                    setInitialValue,
                    setIsEdit,
                    setIsDispatching,
                    gridApi,
                    initialValues
                  )
                );
              } else {
                dispatch(
                  addAssessmentData(
                    values,
                    setOpenAssessment,
                    getAssessmentByCourse,
                    params,
                    setIsDispatching,
                    handleOpenAssessQue,
                    handleSetAssessSpecQuestion
                  )
                );
              }
            }
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            dirty,
          }) => (
            <Form className='min-h-max'>
              <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
                <div className='flex justify-between items-center'>
                  <div>{isEdit ? 'Edit Assessment' : 'Add Assessment'}</div>
                  <div>
                    <AiOutlineClose onClick={() => handleClickClose(dirty)} />
                  </div>
                </div>
              </DialogTitle>
              <DialogContent sx={{ width: '800px' }}>
                <Box className='border border-[#CBD5E1] rounded-md mt-5 p-3 pl-2'>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <label className='text-blue-900 font-medium'>Term</label>
                    </Grid>
                    <Grid item xs={6}>
                      <p>
                        {assessmentInitialValues?.term.label}{' '}
                        {assessmentInitialValues?.year}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <label className='text-blue-900 font-medium'>
                        Course
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      <p className='whitespace-nowrap overflow-hidden text-ellipsis'>
                        {assessmentInitialValues?.course_details_id.name}
                      </p>
                    </Grid>

                    <Grid item xs={6}>
                      <label className='text-blue-900 font-medium'>
                        Course Prefix
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      <p className='whitespace-nowrap overflow-hidden text-ellipsis'>
                        {assessmentInitialValues?.course_prefix_id.name}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
                <Box className='border border-[#CBD5E1] rounded-md mt-5 p-3  h-auto'>
                  <Grid container rowGap={2} className='items-center'>
                    <Grid item xs={6}>
                      <label className='text-blue-900 font-medium'>
                        No of Administrations
                      </label>
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        name='no_of_administrations'
                        variant='standard'
                        size='small'
                        type='number'
                        fullWidth
                        inputProps={{
                          min: isEdit
                            ? assessmentInitialValues?.no_of_administrations
                            : 1,
                          max: 16,
                        }}
                        value={values?.no_of_administrations}
                        helperText={
                          errors.noOfAdministrations &&
                          touched.noOfAdministrations
                            ? errors.noOfAdministrations
                            : ''
                        }
                        error={
                          errors.noOfAdministrations &&
                          touched.noOfAdministrations
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={isEdit ? 6 : 12}>
                      <label className='text-blue-900 font-medium'>
                        Select an assessment
                      </label>
                    </Grid>
                    <Grid item xs={isEdit ? 6 : 12}>
                      <Field
                        disabled={isEdit}
                        name='assessment_id'
                        component={Autocomplete}
                        open
                        value={values?.assessment_id}
                        options={assessmentDetails?.record || []}
                        groupBy={(option: any) => option?.discipline}
                        ListboxProps={{
                          style: isEdit
                            ? { maxHeight: 340, display: 'none' }
                            : { maxHeight: 340 },
                        }}
                        // Add PopperComponent to position the dropdown below the label
                        PopperComponent={(props: any) => (
                          <div
                            {...props}
                            ref={listboxRef}
                            style={{ marginTop: labelHeight }}
                          />
                        )}
                        getOptionLabel={(option: {
                          code: string;
                          discipline: string;
                          official_name: string;
                          under_development: boolean;
                        }) =>
                          !Boolean(option.official_name)
                            ? ''
                            : `${option?.official_name} - ${option?.code}` +
                              (option.under_development
                                ? '- Under Development'
                                : '')
                        }
                        isOptionEqualToValue={(
                          option: {
                            code: string;
                            discipline: string;
                            official_name: string;
                          },
                          current: {
                            code: string;
                            discipline: string;
                            official_name: string;
                          }
                        ) => option === current}
                        onChange={(
                          event: React.SyntheticEvent,
                          assessment_id: {
                            code: string;
                            discipline: string;
                            official_name: string;
                            type: string;
                          }
                        ) => {
                          setAssessmentSelect(assessment_id?.code);
                          setFieldValue('assessment_id', assessment_id);
                        }}
                        onBlur={() => setFieldTouched('assessment_id', true)}
                        filterOptions={(
                          options: any[],
                          { inputValue }: { inputValue: string }
                        ) =>
                          options.filter((option) => {
                            const officialNameMatches = option.official_name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                            const disciplineMatches = option.discipline
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                            const codeMatches = option.code
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                            return (
                              officialNameMatches ||
                              disciplineMatches ||
                              codeMatches
                            );
                          })
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            sx={{
                              '& .MuiAutocomplete-listbox': {
                                maxHeight: '227px',
                              },
                            }}
                            {...params}
                            variant='standard'
                            error={
                              !!errors.assessment_id && !!touched.assessment_id
                            }
                            helperText={
                              !!errors.assessment_id && !!touched.assessment_id
                                ? 'Required Field'
                                : ''
                            }
                            required
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'Search...',
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                      padding: '2px',
                    }}
                  >
                    <div>
                      {' '}
                      <Button
                        variant='contained'
                        disabled={
                          !values?.assessment_id?.code ||
                          values?.assessment_id?.type === 'adaptive'
                        }
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => handleClickView(values.assessment_id)}
                      >
                        View
                      </Button>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Button
                        variant='contained'
                        // fullWidth
                        onClick={() => handleClickClose(dirty)}
                        style={{ textTransform: 'capitalize' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        type='submit'
                        // fullWidth
                        style={{ textTransform: 'capitalize' }}
                        disabled={!values?.assessment_id?.code || isDispatching}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Box>
                {/* question box */}

                <ViewAssessment
                  open={openView}
                  setOpen={setOpenView}
                  data={viewData}
                />
                {/* values
              <pre>{JSON.stringify(values, null, 2)}</pre>
              touched
              <pre>{JSON.stringify(touched, null, 2)}</pre>
              errors
              <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
      {assessSpecQuestion?.length > 0 && (
        <CourseAssessSpecificQuestion
          isOpenClose={openAssessSpecQue}
          handleCloseAssessQue={handleCloseAssessQue}
          handleSaveAssessSpecQue={handleSaveAssessSpecQue}
          question={assessSpecQuestion}
        />
      )}
    </>
  );
}

export default AddCourseAssessment;
