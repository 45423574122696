import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../Store';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as usersInformationSheetRoute from '../route/usersInformationSheetRoute';
import { GridApi } from 'ag-grid-community';
import Alert from '../../utils/Alert/Alert';
import moment from 'moment';

export interface AxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: object;
  data: object;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => object;
}

const initialState = {
  getPreferenceFileData: [],
  loading: false,
};

const getStudentListSheetDownload =
  (setStudentListSheet: any) => async (dispatch: AppDispatch) => {
    return usersInformationSheetRoute
      .getStudentListSheetDownload()
      .then((res: AxiosResponse) => {
        if (res?.data?.name) {
          const studentListSheet = res.data.name;
          setStudentListSheet(studentListSheet);
        }
      })
      .catch((err: AxiosError) => {});
  };

const getPendingInvitesSheetDownload =
  (SetPendingInvitesSheet: any) => async (dispatch: AppDispatch) => {
    return usersInformationSheetRoute
      .getPendingInvitesSheetDownload()
      .then((res: AxiosResponse) => {
        if (res?.data?.name) {
          const pendingInvitesSheet = res.data.name;
          SetPendingInvitesSheet(pendingInvitesSheet);
        }
      })
      .catch((err: AxiosError) => {});
  };

const getPendingApprovalsSheetDownload =
  (SetPendingApprovalsSheet: any) => async (dispatch: AppDispatch) => {
    return usersInformationSheetRoute
      .getPendingApprovalsSheetDownload()
      .then((res: AxiosResponse) => {
        if (res?.data?.name) {
          const pendingApprovalsSheet = res.data.name;
          SetPendingApprovalsSheet(pendingApprovalsSheet);
        }
      })
      .catch((err: AxiosError) => {});
  };

const getRejectUsersSheetDownload =
  (SetRejectUsersSheet: any) => async (dispatch: AppDispatch) => {
    return usersInformationSheetRoute
      .getRejectUsersSheetDownload()
      .then((res: AxiosResponse) => {
        if (res?.data?.name) {
          const rejectUsersSheet = res.data.name;
          SetRejectUsersSheet(rejectUsersSheet);
        }
      })
      .catch((err: AxiosError) => {});
  };

const PreferenceFileSlice = createSlice({
  name: 'PreferenceFileSlice',
  initialState,
  reducers: {
    handleGetProgram: (state, action) => {
      state.getPreferenceFileData = action.payload;
    },

    handleLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

//reducers export  to pass action state to reducer type
export const { handleGetProgram, handleLoading } = PreferenceFileSlice.actions;

//action to calls in ui with dispatch methods
export {
  getStudentListSheetDownload,
  getPendingInvitesSheetDownload,
  getPendingApprovalsSheetDownload,
  getRejectUsersSheetDownload,
};

export default PreferenceFileSlice.reducer;
