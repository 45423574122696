import api from './index';

const getStudentListSheetDownload = () =>
  api.get(`/users_information_sheet/student-file`);
const getPendingInvitesSheetDownload = () =>
  api.get(`/users_information_sheet/pending-invites-sheet`);
const getPendingApprovalsSheetDownload = () =>
  api.get(`/users_information_sheet/pending-approvals-sheet`);
const getRejectUsersSheetDownload = () =>
  api.get(`/users_information_sheet/reject-users-sheet`);

export {
  getStudentListSheetDownload,
  getPendingInvitesSheetDownload,
  getPendingApprovalsSheetDownload,
  getRejectUsersSheetDownload,
};
