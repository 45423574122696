import React, { useEffect, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  getAdaptiveAssessmentQuestion,
  checkAssessmentStatus,
  getAssessmentById,
  takeAssessment,
  getStudentAssessmentData,
} from '../../reduxStore/reducer/assessmentReducer';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import {
  MenuItem,
  Box,
  Tabs,
  Tab,
  Typography,
  Radio,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  OutlinedInput,
  DialogTitle,
  Checkbox,
  TextField,
  Button,
  Paper,
} from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Swal from 'sweetalert2';
import Alert from '../../utils/Alert/Alert';
import moment, { duration } from 'moment';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import CryptoJS from 'crypto-js';
import { CircularProgress } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import { Field } from 'formik';
interface TabPanelProps {
  children?: React.ReactNode;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TakeAssessmentQuestion = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  // includes assessment and their question data
  const [val, setVal] = React.useState<any>({});
  // indicates whether the student submitted or saved
  const [isSubmitted, setSubmitted] = React.useState(false);
  // indicates whether the student submitted or saved
  const [testStatus, setTestStatus] = React.useState<any>(false);
  // indicates the currently viewing question
  const [activeStep, setActiveStep] = React.useState(0);
  // includes the current question properties
  const [currentQuestion, setcurrentQuestion] = useState<any>(null);
  // to disable the submit button after clicked
  const [button, setButton] = useState(false);
  // sum of individual time taken by the student for each question
  const [totalTimeTaken, setTotalTimeTaken] = useState(0);
  // includes all framing properties not-for-adaptive (NFA)
  const [framing, setFraming] = React.useState<any>([]);
  // includes all question properties
  const [questions, setQuestions] = useState<any>([]);
  const [milestone, setMilestone] = React.useState(9); // Track the next multiple of 10
  const isInitialRender = React.useRef(true);

  // framing condition to remove unneccesary while rendering (NFA)
  const framingCondition = (item: any) => {
    const res = framing.find((e: any) => e.framingLabel === item);

    if (res) return res.framingContent?.replace(/\\/g, '');
    else return '';
  };

  //get assessment & their question data
  const selectedData: any = useSelector(
    (state: RootState) => state.assessment.assessmentById
  );

  //get the student data who taking the assessment
  const assessmentStatusData: any = useSelector(
    (state: RootState) => state.assessment.assessmentStatus
  );

  //get the student data with runtime answers selected
  const studentAssessmentData: any = useSelector(
    (state: RootState) => state.assessment.studentAssessmentData
  );

  //deprecated
  // const session = {
  //   course_student_id: location?.state?.course_student_id,
  //   course_assessment_id: location?.state?.course_assessment_id,
  //   administration_no: location?.state?.administration_no,
  // };

  const handleRadioChange = (e: any, i: any) => {
    //change the answer for particular question
    setQuestions((questions: any) => {
      return questions.map((question: any, index: any) => {
        if (index === i) {
          const otherOptionIndex = question?.responseOptions?.findIndex(
            (option: any) => option.optionType == 'other'
          );

          // Check if the otherOptionIndex is the same as the answer
          if (otherOptionIndex == question?.answer) {
            // Remove the otherText property if it exists
            const { otherText, ...rest } = question;
            return {
              ...rest,
              answer: e.target.value,
            };
          } else {
            // Return the question object with the updated answer
            return {
              ...question,
              answer: e.target.value,
            };
          }
        }
        return question;
      });
    });

    //change the answer in another hook
    setVal((val: any) => {
      const questionCopy = { ...questions[i] };
      delete questionCopy?.answer;
      delete questionCopy.duration;

      const res = {
        ...val,
        question_data: {
          ...val.question_data,
          data: val.question_data.data.map((dataItem: any) => {
            const dataItemCopy = { ...dataItem };
            delete dataItemCopy?.answer;
            delete dataItemCopy.duration;
            delete questionCopy.duration;

            if (JSON.stringify(dataItemCopy) === JSON.stringify(questionCopy)) {
              const otherOptionIndex = dataItem?.responseOptions?.findIndex(
                (option: any) => option.optionType == 'other'
              );

              // // Check if the otherOptionIndex is the same as the answer
              // if (otherOptionIndex == dataItem?.answer) {
              // Remove the otherText property if it exists
              const { otherText, ...rest } = dataItem;
              return {
                ...rest,
                answer: e.target.value,
              };
              // }
            } else {
              return dataItem;
            }
            // ? {
            //     ...dataItem,
            //     answer: e.target.value,
            //     duration: dataItem.duration,
            //   }
            // : dataItem;
          }),
        },
      };
      return res;
    });
  };

  const onCheckboxChange = (e: any, i: any) => {
    const { name, checked } = e.target;

    setQuestions((questions: any) =>
      questions.map((question: any, index: any) => {
        if (index === i) {
          const options = question?.responseOptions;
          //find the other option index
          let otherOptionIndex = options?.findIndex(
            (option: any) => option.optionType == 'other'
          );

          //remove the text on other field if other option is false
          if (
            otherOptionIndex == name &&
            question?.answer &&
            Object.hasOwn(question?.answer, otherOptionIndex) &&
            question?.answer[otherOptionIndex] == true
          ) {
            // Remove the otherText property if it exists
            // const { otherText, ...rest } = question;
            delete question.otherText;
          }

          const notaObj = options?.filter(
            (option: any) => option?.optionType === 'none'
          )?.[0];
          let notaObjOptionLabel = options?.findIndex(
            (option: any) => option.optionType == 'none'
          );

          // if (notaObjOptionLabel >= 0) {
          //   notaObjOptionLabel++;
          // }

          const currentCheckbox = name;
          if (currentCheckbox == notaObjOptionLabel) {
            const isOtherCheckboxFalse = options.every(
              (option: any, optionLabel: any) => {
                if (
                  optionLabel !== notaObjOptionLabel &&
                  Object.hasOwn(question?.answer, optionLabel)
                ) {
                  return question?.answer[optionLabel] === false;
                } else {
                  return true;
                }
              }
            );
            if (isOtherCheckboxFalse) {
              return {
                ...question,
                answer: { ...question.answer, [name]: checked },
                duration: question.duration,
              };
            } else {
              /* current checkbox is "none of the above" & some of others are checked */
              const data = {
                ...question?.answer,
              };

              //so make other checkbox as false
              options.forEach((option: any, optionLabel: any) => {
                if (
                  optionLabel !== notaObjOptionLabel &&
                  Object.hasOwn(question?.answer, optionLabel)
                ) {
                  if (question?.answer[optionLabel] === true) {
                    data[optionLabel] = false;

                    if (Object.hasOwn(question, 'otherText')) {
                      delete question.otherText;
                    }
                  }
                } else {
                  /* option is false so logic never came here */
                  //return true;
                }
              });

              //make current(none) as true
              data[name] = checked;
              question.answer = data;
              return question;

              // Alert.info({
              //   title:
              //     'Either None of the above or other checkboxes will be selected',
              //   text: '',
              // });
              // return question;
            }
          } else {
            if (notaObj) {
              if (
                Object.hasOwn(question?.answer, notaObjOptionLabel) &&
                question?.answer[notaObjOptionLabel] === true
              ) {
                /* current checkbox is other than "none of the above" and "none of the above" is checked */

                //so make "none of the above" checkbox as false & make current(some of other) as true
                const data = {
                  ...question?.answer,
                  [notaObjOptionLabel]: false,
                  [name]: checked,
                };
                question.answer = data;
                return question;
                // Alert.info({
                //   title:
                //     'Either None of the above or other checkboxes will be selected',
                //   text: '',
                // });
                // return question;
              } else {
                return {
                  ...question,
                  answer: { ...question.answer, [name]: checked },
                  duration: question.duration,
                };
              }
            } else {
              return {
                ...question,
                answer: { ...question.answer, [name]: checked },
                duration: question.duration,
              };
            }
          }
        } else {
          return question;
        }
      })
    );

    setVal((val: any) => {
      const questionCopy = { ...questions[i] };
      delete questionCopy?.answer;
      delete questionCopy?.otherText;

      const res = {
        ...val,
        question_data: {
          ...val.question_data,
          data: val.question_data.data.map((dataItem: any, index: any) => {
            // if (index == i) {
            //   return {
            //     ...dataItem,
            //     answer: { ...dataItem.answer, [name]: checked },
            //     duration: dataItem.duration,
            //   };
            // } else {
            //   return dataItem;
            // }
            const dataItemCopy = { ...dataItem };
            delete dataItemCopy?.answer;
            delete dataItemCopy.duration;
            delete dataItemCopy?.otherText;
            delete questionCopy.duration;

            if (JSON.stringify(dataItemCopy) === JSON.stringify(questionCopy)) {
              // return {
              //   ...dataItem,
              //   answer: { ...dataItem.answer, [name]: checked },
              //   duration: dataItem.duration,
              // };
              const options = dataItemCopy?.responseOptions;

              //find the other option index
              let otherOptionIndex = options?.findIndex(
                (option: any) => option.optionType == 'other'
              );

              //remove the text on other field if other option is false
              if (
                otherOptionIndex == name &&
                dataItem?.answer &&
                Object.hasOwn(dataItem?.answer, otherOptionIndex) &&
                dataItem?.answer[otherOptionIndex] == true
              ) {
                // Remove the otherText property if it exists
                // const { otherText, ...rest } = dataItem;
                delete dataItem.otherText;
              }

              const notaObj = options?.filter(
                (option: any) => option?.optionType === 'none'
              )?.[0];
              let notaObjOptionLabel = options?.findIndex(
                (option: any) => option.optionType == 'none'
              );

              // if (notaObjOptionLabel >= 0) {
              //   notaObjOptionLabel++;
              // }

              const currentCheckbox = name;
              if (currentCheckbox == notaObjOptionLabel) {
                const isOtherCheckboxFalse = options.every(
                  (option: any, optionLabel: any) => {
                    if (
                      optionLabel !== notaObjOptionLabel &&
                      Object.hasOwn(dataItem?.answer, optionLabel)
                    ) {
                      return dataItem?.answer[optionLabel] === false;
                    } else {
                      return true;
                    }
                  }
                );
                if (isOtherCheckboxFalse) {
                  return {
                    ...dataItem,
                    answer: { ...dataItem.answer, [name]: checked },
                    duration: dataItem.duration,
                  };
                } else {
                  /* current checkbox is "none of the above" & some of others are checked */
                  const data = {
                    ...dataItem?.answer,
                  };

                  //so make other checkbox as false
                  options.forEach((option: any, optionLabel: any) => {
                    if (
                      optionLabel !== notaObjOptionLabel &&
                      Object.hasOwn(dataItem?.answer, optionLabel)
                    ) {
                      if (dataItem?.answer[optionLabel] === true) {
                        data[optionLabel] = false;
                        if (Object.hasOwn(dataItem, 'otherText')) {
                          delete dataItem.otherText;
                        }
                      }
                    } else {
                      /* option is false so logic never came here */
                      //return true;
                    }
                  });

                  //make current(none) as true
                  data[name] = checked;
                  dataItem.answer = data;
                  return dataItem;

                  // Alert.info({
                  //   title:
                  //     'Either None of the above or other checkboxes will be selected',
                  //   text: '',
                  // });
                  // return dataItem;
                }
              } else {
                if (notaObj) {
                  if (
                    Object.hasOwn(dataItem?.answer, notaObjOptionLabel) &&
                    dataItem?.answer[notaObjOptionLabel] === true
                  ) {
                    /* current checkbox is other than "none of the above" and "none of the above" is checked */

                    //so make "none of the above" checkbox as false & make current(some of other) as true
                    const data = {
                      ...dataItem?.answer,
                      [notaObjOptionLabel]: false,
                      [name]: checked,
                    };
                    dataItem.answer = data;
                    return dataItem;
                    // Alert.info({
                    //   title:
                    //     'Either None of the above or other checkboxes will be selected',
                    //   text: '',
                    // });
                    // return dataItem;
                  } else {
                    return {
                      ...dataItem,
                      answer: { ...dataItem.answer, [name]: checked },
                      duration: dataItem.duration,
                    };
                  }
                } else {
                  return {
                    ...dataItem,
                    answer: { ...dataItem.answer, [name]: checked },
                    duration: dataItem.duration,
                  };
                }
              }
            } else {
              return dataItem;
            }
          }),
        },
      };
      return res;
    });
  };

  const onInputChange = (e: any, i: any, type?: any) => {
    const { name, value: inputData } = e.target;

    // setQuestions((questions: any) =>
    //   questions.map((question: any, index: any) =>
    //     index === i ? { ...question, answer: inputData } : question
    //   )
    // );
    setQuestions((questions: any) =>
      questions.map((question: any, index: any) => {
        if (index === i) {
          if (type === 'Single' || type === 'Multiple') {
            return { ...question, otherText: inputData };
          } else {
            return { ...question, answer: inputData };
          }
        }
        return question;
      })
    );

    setVal((val: any) => {
      const questionCopy = { ...questions[i] };
      delete questionCopy?.answer;

      const res = {
        ...val,
        question_data: {
          ...val.question_data,
          data: val.question_data.data.map((dataItem: any, index: any) => {
            const dataItemCopy = { ...dataItem };
            delete dataItemCopy?.answer;
            delete dataItemCopy.duration;
            delete questionCopy.duration;

            if (JSON.stringify(dataItemCopy) === JSON.stringify(questionCopy)) {
              if (type === 'Single' || type === 'Multiple') {
                return {
                  ...dataItem,
                  otherText: inputData,
                  duration: dataItem.duration,
                };
              } else {
                return {
                  ...dataItem,
                  answer: inputData,
                  duration: dataItem.duration,
                };
              }
            } else {
              return dataItem;
            }
          }),
        },
      };
      return res;
    });
  };

  const theme = useTheme();

  //update the duration from current question into val data and the change the current question
  const updateDuration = (currentQuestionIndex: any) => {
    if (currentQuestion) {
      if (location?.state?.assessment_type === 'adaptive') {
        setVal((val: any) => {
          const res = {
            ...val,
            question_data: {
              ...val.question_data,
              data: val?.question_data?.data?.map((dataItem: any, i: any) => {
                if (i === activeStep) {
                  const dataItemCopy = {
                    ...dataItem,
                    duration: currentQuestion.duration,
                  };
                  return dataItemCopy;
                } else {
                  return dataItem;
                }
              }),
            },
          };
          setQuestions(res.question_data.data);
          setcurrentQuestion(res.question_data.data[currentQuestionIndex]);
          return res;
        });
      } else {
        setVal((val: any) => {
          const res = {
            ...val,
            question_data: {
              ...val.question_data,
              data: val?.question_data?.data?.map((dataItem: any, i: any) => {
                if (dataItem?.index === activeStep) {
                  const dataItemCopy = {
                    ...dataItem,
                    duration: currentQuestion.duration,
                  };
                  return dataItemCopy;
                } else {
                  return dataItem;
                }
              }),
            },
          };
          setQuestions(
            res.question_data.data.filter((e: any) => e.type === 'question')
          );
          setcurrentQuestion(
            res.question_data.data.filter((e: any) => e.type === 'question')[
              currentQuestionIndex
            ]
          );
          return res;
        });
      }
    }
  };

  const handleBack = () => {
    isInitialRender.current = false;
    updateDuration(activeStep - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    isInitialRender.current = false;
    questions[activeStep + 1]?.questionContent?.replace(
      /<span class="se-custom-tag">\[Question (\d+)\]/g,
      (match: any, p1: any) => {
        const questionIndex = parseInt(p1) - 1;
        const answer = questions[questionIndex]?.answer;
        if (!answer) {
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            html: `Before answering this question, you must first answer question(s):<br>  <b><div class="sun-editor-editable" style="padding-top: 0px;
    padding-bottom: 0px; max-width: 918px;">${questions[
      activeStep
    ]?.questionContent?.replace(
      /<span class="se-custom-tag">\[Question (\d+)\]/g,
      (match: any, p1: any) => `${questions[parseInt(p1) - 1]?.answer}`
    )}</div></b>`,
          }).then(() => {
            setActiveStep(questionIndex);
          });
          return match; // return the original match
        }
        return answer;
      }
    );

    if (location?.state?.assessment_type === 'adaptive') {
      let answerFlag = false;
      if (questions?.[activeStep]?.responseType === 'Multiple') {
        let trueKeys = Object.keys(questions?.[activeStep]?.answer).filter(
          (key) => questions?.[activeStep]?.answer[key] === true
        );

        answerFlag = trueKeys.length > 0 ? false : true;
      } else {
        answerFlag = questions?.[activeStep]?.answer ? false : true;
      }

      if (answerFlag) {
        Swal.fire({
          title: `You cannot go back to previous answers in this survey. Are you sure you want to move to the next question without selecting an answer?`,
          showCancelButton: true,
          confirmButtonText: 'Next Question',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            if (activeStep + 2 > questions?.length) {
              setQuestions((questions: any) => {
                const data = val.question_data.data.map(
                  (question: any, index: any) =>
                    index === activeStep
                      ? { ...question, answer: '-99' }
                      : question
                );

                dispatch(
                  getAdaptiveAssessmentQuestion(
                    location?.state?.course_assessment_id,
                    setQuestions,
                    setVal,
                    '-99',
                    false,
                    location?.state?.course_student_id,
                    location?.state?.administration_no,
                    setcurrentQuestion,
                    data[activeStep].id,
                    '',
                    navigate,
                    data,
                    currentQuestion?.duration,
                    setActiveStep
                  )
                );
                return data;
              });

              setVal((val: any) => {
                const res = {
                  ...val,
                  question_data: {
                    ...val.question_data,
                    data: val?.question_data?.data?.map(
                      (dataItem: any, i: any) => {
                        if (i === activeStep) {
                          const dataItemCopy = {
                            ...dataItem,
                            answer: '-99',
                          };
                          return dataItemCopy;
                        } else {
                          return dataItem;
                        }
                      }
                    ),
                  },
                };
                return res;
              });
            } else {
              setQuestions((questions: any) =>
                questions.map((question: any, index: any) =>
                  index === activeStep
                    ? { ...question, answer: '-99' }
                    : question
                )
              );

              setVal((val: any) => {
                const res = {
                  ...val,
                  question_data: {
                    ...val.question_data,
                    data: val?.question_data?.data?.map(
                      (dataItem: any, i: any) => {
                        if (i === activeStep) {
                          const dataItemCopy = {
                            ...dataItem,
                            answer: '-99',
                          };
                          return dataItemCopy;
                        } else {
                          return dataItem;
                        }
                      }
                    ),
                  },
                };
                return res;
              });
            }
            updateDuration(activeStep + 1);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            return;
          }
        });
      } else {
        if (activeStep + 2 > questions?.length) {
          dispatch(
            getAdaptiveAssessmentQuestion(
              location?.state?.course_assessment_id,
              setQuestions,
              setVal,
              questions[activeStep].answer,
              false,
              location?.state?.course_student_id,
              location?.state?.administration_no,
              setcurrentQuestion,
              questions[activeStep].id,
              '',
              navigate,
              val.question_data.data,
              currentQuestion?.duration,
              setActiveStep
            )
          );
          // dispatch(
          //   getStudentAssessmentData(
          //     {
          //       course_assessment_id: location?.state?.course_assessment_id,
          //       course_student_id: location?.state?.course_student_id,
          //       administration_no: location?.state?.administration_no,
          //     },
          //     navigate
          //   )
          // );
        }
        updateDuration(activeStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      updateDuration(activeStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleSubmit = (testStatus: any) => {
    if (testStatus) {
      setTestStatus(testStatus);
      updateDuration(activeStep);
      setSubmitted(true);
    } else {
      Swal.fire({
        title: `Are you sure you want to save this assessment and complete it later?`,
        icon: 'info',
        showCancelButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setTestStatus(testStatus);
          updateDuration(activeStep);
          setSubmitted(true);
        }
        // If the user clicked "Cancel," do nothing and let the dialog close.
      });
    }
  };

  useEffect(() => {
    questions[activeStep]?.questionContent?.replace(
      /<span class="se-custom-tag">\[Question (\d+)\]/g,
      (match: any, p1: any) => {
        const questionIndex = parseInt(p1) - 1;
        const answer = questions[questionIndex]?.answer;
        const MultipleAnswer =
          Object.keys(answer)
            .filter((key) => answer[key] === true)
            .map(Number).length > 0;
        if (
          questions[questionIndex].responseType === 'Multiple'
            ? !MultipleAnswer
            : !answer
        ) {
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            html: `Before answering this question, you must first answer question(s):<br>  <b><div class="sun-editor-editable" style="padding-top: 0px;
    padding-bottom: 0px; max-width: 918px;">${questions[
      questionIndex
    ]?.questionContent?.replace(
      /<span class="se-custom-tag">\[Question (\d+)\]/g,
      (match: any, p1: any) => {
        if (questions[questionIndex].responseType === 'Multiple') {
          let answerKeys = Object.entries(questions[questionIndex]?.answer)
            .filter(([key, value]) => value === true)
            .map(([key, value]) => {
              let optionContent =
                questions[questionIndex]?.responseOptions[key]?.optionContent;
              return optionContent
                ? optionContent.replace(/<\/?p>/g, '')
                : '[response from prior question]';
            });

          if (answerKeys.length > 1) {
            let lastKey = answerKeys.pop();
            return `${answerKeys.join(', ')} and ${lastKey}`;
          } else if (answerKeys.length === 0) {
            return `[response from prior question]`;
          } else {
            return answerKeys.join(', ');
          }
        } else {
          return `${questions[questionIndex]?.answer}`;
        }
      }
    )}</div></b>`,
          }).then(() => {
            setActiveStep(questionIndex);
          });
          return match; // return the original match
        }
        return answer;
      }
    );
  }, [activeStep]);
  // }
  // });

  // const imageAlternateData = arr?.map((d: any) => {
  //   const reg: any = /alt="(.*?)"/;
  //   const altText = reg.exec(d)[1];
  //   return { image: d, alt: altText ? altText : '' };
  // });
  // */
  const [refImage, setRefImage] = useState([]);
  const [imageDescription, SetImageDescription] = useState([]);
  // const saveAnswersLocally = (val: any) => {
  //   const duplicateAssessmentStatus = {
  //     answer: {
  //       answer: [],
  //     },
  //   };

  //   duplicateAssessmentStatus.answer.answer = val?.question_data?.data?.map(
  //     (ques: any) => {
  //       return {
  //         label: ques?.questionLabel,
  //         likert: '',
  //         answer: ques?.answer,
  //         duration: ques?.duration,
  //       };
  //     }
  //   );

  //   localStorage.setItem(
  //     'answerData',
  //     JSON.stringify(duplicateAssessmentStatus)
  //   );
  // };

  useEffect(() => {
    const postAnswers = (val: any) => {
      location.state.test_status = false;

      if (location?.state?.assessment_type === 'adaptive') {
        location.state.answer = val;
        location.state.type = 'assessment';

        dispatch(takeAssessment(location.state, () => {}, false, true));
        return;
      }

      location.state.answer = val;
      location.state.type = 'assessment';

      dispatch(takeAssessment(location.state, () => {}, false, true));
      return;
    };
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      postAnswers(val);
      event.returnValue = ''; // This is required for Chrome to show the warning dialog
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        console.log('visibilty changed: ', document.visibilityState);
        postAnswers(val);
      }
    };

    const handlePageHide = () => {
      console.log('page hidden');
      postAnswers(val); // Works similarly to beforeunload on mobile
    };

    if (location?.state?.assessment_type === 'static') {
      // Listen for visibility changes
      window.addEventListener('visibilitychange', handleVisibilityChange);

      // Listen for page unload events (beforeunload and pagehide)
      window.addEventListener('beforeunload', handleBeforeUnload);

      window.addEventListener('pagehide', handlePageHide);
    }

    return () => {
      if (location?.state?.assessment_type === 'static') {
        window.removeEventListener('visibilitychange', handleVisibilityChange);
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('pagehide', handlePageHide);
      }
    };
  }, [val]);
  //fetch student data who taking the assessment and assessment & their question data and increments duration of the current question, recursively
  useEffect(() => {
    // function saveAnswers() {
    //   console.log('Saving answerss...');

    //   setTestStatus(false);
    //   setSave(true);
    //   setSubmitted(true);
    // }

    //to fetch student data who taking the assessment
    dispatch(
      checkAssessmentStatus(
        {
          course_assessment_id: location?.state?.course_assessment_id,
          course_student_id: location?.state?.course_student_id,
          administration_no: location?.state?.administration_no,
        },
        navigate,
        '',
        setRefImage,
        SetImageDescription
      )
    );

    if (location?.state?.assessment_type === 'adaptive') {
      // to fetch all assessment & their question data (propabaly one question data)
      dispatch(
        getAdaptiveAssessmentQuestion(
          location?.state?.course_assessment_id,
          '',
          '',
          '',
          true,
          location?.state?.course_student_id,
          location?.state?.administration_no,
          '',
          '',
          'firstRender'
        )
      );

      dispatch(
        getStudentAssessmentData(
          {
            course_assessment_id: location?.state?.course_assessment_id,
            course_student_id: location?.state?.course_student_id,
            administration_no: location?.state?.administration_no,
          },
          navigate
        )
      );
    } else {
      //to fetch assessment & their question data
      dispatch(getAssessmentById(location?.state?.course_assessment_id));
    }

    const interval = setInterval(() => {
      setcurrentQuestion((prev: any) => {
        if (!prev) return;
        return {
          ...prev,
          duration: +prev.duration + 1,
        };
      });
    }, 1000);
    //@ts-ignore
    return () => {
      clearInterval(interval);
      // clearInterval(savingAnswersPeriodically);
    };
  }, []);

  //adds duration property on all questions
  useEffect(() => {
    if (location?.state?.assessment_type === 'adaptive') {
      // student saved and return to the assessment

      //make it false
      if (
        selectedData?.data?.question_data?.data?.length &&
        assessmentStatusData?.answer?.answer?.length
      ) {
        const temp: any = [];

        selectedData?.data?.question_data?.data?.forEach((d: any) => {
          const tem = { ...d };

          //find question obj from student answers
          const singleQues = assessmentStatusData.answer.answer.find(
            (e: any) => e.question_id == tem.id
          );
          if (singleQues?.answer) {
            tem.answer = singleQues.answer;
            // temp.push(tem);
          }
          if (singleQues?.duration) {
            //adding duration on question data
            tem.duration = singleQues.duration;
          } else {
            tem.duration = 0;
          }
          temp.push(tem);
        });

        const data = { ...selectedData };
        const assq = { ...data.data };
        const newData = { ...assq.question_data };
        newData.data = temp;
        assq.question_data = newData;

        setVal(assq);
        setQuestions(newData.data);
        setcurrentQuestion(assq.question_data.data[activeStep]);

        let tempActiveStep = assessmentStatusData?.answer?.answer?.findIndex(
          (e: any) => e.answer === ''
        );

        setActiveStep(
          tempActiveStep >= 0
            ? tempActiveStep
            : assessmentStatusData?.answer?.answer.length - 1
        );
      } else if (selectedData?.data?.question_data?.data?.length) {
        //adds duration property on all questions
        const updatedData = {
          ...selectedData,
          data: {
            ...selectedData.data,
            question_data: {
              ...selectedData.data.question_data,
              data: selectedData.data.question_data.data.map((e: any) => {
                return {
                  ...e,
                  //['responseType']: 'Multiple',
                  duration: 0,
                };
              }),
            },
          },
        };

        // update the assessment & their question data (particularly question data)
        setVal(updatedData?.data);

        // take questions separately
        setQuestions(updatedData?.data.question_data.data);

        // take current question separately
        setcurrentQuestion(updatedData?.data.question_data.data[activeStep]);
      }
    } /*NFA*/ else {
      // student saved and return to the assessment
      if (
        selectedData?.data?.question_data?.data?.length &&
        assessmentStatusData?.answer?.answer?.length
      ) {
        const temp: any = [];
        selectedData?.data?.question_data?.data?.forEach((d: any) => {
          if (d.type == 'question') {
            const tem = { ...d };
            //find question obj from student answers
            const singleQues = assessmentStatusData.answer.answer.find(
              (e: any) => e.label == tem.questionLabel
            );
            if (singleQues.answer) {
              tem.answer = singleQues.answer;
            }
            if (singleQues.duration) {
              //adding duration on question data
              tem.duration = singleQues.duration;
            } else {
              tem.duration = 0;
            }
            temp.push(tem);
          } else {
            temp.push(d);
          }
        });

        const data = { ...selectedData };
        const assq = { ...data.data };
        const newData = { ...assq.question_data };
        newData.data = temp;
        assq.question_data = newData;

        setVal(assq);
        setcurrentQuestion(
          assq.question_data.data.filter((e: any) => e.type === 'question')[
            activeStep
          ]
        );

        let tempActiveStep = assessmentStatusData?.answer?.answer?.findIndex(
          (item: any) => item.answer === ''
        );
        setActiveStep(
          tempActiveStep >= 0
            ? tempActiveStep
            : assessmentStatusData?.answer?.answer?.length - 1
        );

        setQuestions(
          //traverse the questions
          selectedData.data.question_data.data
            .map((d: any) => {
              if (d.type == 'question') {
                const temp = { ...d };

                //find answer for the current question
                const singleQues = assessmentStatusData.answer.answer.find(
                  (e: any) => e.label == temp.questionLabel
                );
                if (singleQues && singleQues?.answer) {
                  temp.answer = singleQues.answer;
                  return temp;
                } else {
                  return temp;
                }
              }
            })
            .filter(Boolean)
        );

        // take framing separately
        setFraming(
          selectedData.data.question_data.data.filter(
            (e: any) => e.type === 'framing'
          )
        );
      } else if (selectedData?.data?.question_data?.data?.length) {
        //adds duration property on all questions
        const updatedData = {
          ...selectedData,
          data: {
            ...selectedData.data,
            question_data: {
              ...selectedData.data.question_data,
              data: selectedData.data.question_data.data.map((e: any) => {
                if (e?.type === 'question') {
                  return {
                    ...e,
                    duration: 0,
                  };
                } else {
                  return e;
                }
              }),
            },
          },
        };

        // update the assessment & their question data (particularly question data)
        setVal(updatedData?.data);

        // take questions separately
        setQuestions(
          selectedData.data.question_data.data.filter(
            (e: any) => e.type === 'question'
          )
        );

        // take current question separately
        setcurrentQuestion(
          updatedData?.data.question_data.data.filter(
            (e: any) => e.type === 'question'
          )[activeStep]
        );

        // take framing separately
        setFraming(
          selectedData.data.question_data.data.filter(
            (e: any) => e.type === 'framing'
          )
        );
      }
      //setting the test items assessment length
      if (selectedData?.data?.test_assessment_ids?.length > 0) {
        location.state.test_assessment_ids =
          selectedData?.data?.test_assessment_ids;
      }
    }
  }, [selectedData, assessmentStatusData]);

  //DOES NOTHING ON ADAPTIVE
  useEffect(() => {
    if (location?.state?.assessment_type === 'adaptive') {
    } else {
      //to remove the unbreakablity
      if (
        selectedData?.data?.question_data?.data?.length &&
        assessmentStatusData?.answer?.answer?.length
      ) {
        const temp: any = [];
        selectedData?.data?.question_data?.data?.forEach((d: any) => {
          if (d.type == 'question') {
            const tem = { ...d };
            //find question obj from student answers
            const singleQues = assessmentStatusData.answer.answer.find(
              (e: any) => e.label == tem.questionLabel
            );
            if (singleQues.answer) {
              tem.answer = singleQues.answer;
            }
            if (singleQues.duration) {
              //adding duration on question data
              tem.duration = singleQues.duration;
            } else {
              tem.duration = 0;
            }
            temp.push(tem);
          } else {
            temp.push(d);
          }
        });

        const data = { ...selectedData };
        const assq = { ...data.data };
        const newData = { ...assq.question_data };
        newData.data = temp;
        assq.question_data = newData;

        setVal(assq);
        setcurrentQuestion(
          assq.question_data.data.filter((e: any) => e.type === 'question')[
            activeStep
          ]
        );

        let tempActiveStep = assessmentStatusData?.answer?.answer?.findIndex(
          (item: any) => item.answer === ''
        );
        setActiveStep(
          tempActiveStep >= 0
            ? tempActiveStep
            : assessmentStatusData?.answer?.answer?.length - 1
        );

        setQuestions(
          //traverse the questions
          selectedData.data.question_data.data
            .map((d: any) => {
              if (d.type == 'question') {
                const temp = { ...d };

                //find answer for the current question
                const singleQues = assessmentStatusData.answer.answer.find(
                  (e: any) => e.label == temp.questionLabel
                );
                if (singleQues && singleQues?.answer) {
                  temp.answer = singleQues.answer;
                  return temp;
                } else {
                  return temp;
                }
              }
            })
            .filter(Boolean)
        );
      } else if (selectedData?.data?.question_data?.data?.length) {
        //adds duration property on all questions
        const updatedData = {
          ...selectedData,
          data: {
            ...selectedData.data,
            question_data: {
              ...selectedData.data.question_data,
              data: selectedData.data.question_data.data.map((e: any) => {
                if (e?.type === 'question') {
                  return {
                    ...e,
                    duration: 0,
                  };
                } else {
                  return e;
                }
              }),
            },
          },
        };

        // update the assessment & their question data (particularly question data)
        setVal(updatedData?.data);

        // take questions separately
        setQuestions(
          selectedData.data.question_data.data.filter(
            (e: any) => e.type === 'question'
          )
        );

        // take current question separately
        setcurrentQuestion(
          updatedData?.data.question_data.data.filter(
            (e: any) => e.type === 'question'
          )[activeStep]
        );

        // take framing separately
        setFraming(
          selectedData.data.question_data.data.filter(
            (e: any) => e.type === 'framing'
          )
        );
      }
    }
  }, [selectedData, assessmentStatusData]);

  //submits the assessment when time limit reached
  useEffect(() => {
    if (location?.state?.assessment_type === 'adaptive') {
    } else {
      //to remove the unbreakablity
      if (
        val &&
        !(
          Object.keys(val)?.length &&
          Object.keys(currentQuestion)?.length &&
          'duration' in currentQuestion
        )
      )
        return;

      //auto submit deprecated from 26 jan 24
      //when minimum duration is reached, submit the assessment programmatically
      // if (totalTimeTaken === +val.minimum_duration * 60) {
      //   //update the current question duration
      //   updateDuration(activeStep);
      //   //change the status as submitted
      //   location.state.test_status = true;
      //   //
      //   location.state.answer = val;
      //   setButton(true);
      //   // deprecated
      //   // dispatch(updateSession({ ...session, sessionFlag: false }));
      //   location.state.type = 'assessment';
      //   dispatch(takeAssessment(location.state, navigate, true));
      //   setTotalTimeTaken(totalTimeTaken + 1);
      //   return;
      // }

      //calculates the overall time taken by the student
      let currentTotalTimeTaken = val.question_data?.data.reduce(
        (acc: any, curr: any, index: number) => {
          if (index !== activeStep) {
            return acc + curr.duration;
          }
          return acc;
        },
        currentQuestion.duration
      );
      setTotalTimeTaken(currentTotalTimeTaken);
    }
  }, [val, currentQuestion]);

  //manual submit (pedning for apadative)
  useEffect(() => {
    if (isSubmitted) {
      location.state.test_status = testStatus;

      const submittedAt = moment().format('YYYY-MM-DDTHH:mm');

      const utcSubmittedAt = moment.utc(submittedAt).toISOString();

      if (location?.state?.assessment_type === 'adaptive') {
        // Alert.success({ title: 'Assessment Completed', text: '' }).then(() =>
        //   navigate('/login')
        // );
        if (testStatus === true) {
          location.state.submittedAt = utcSubmittedAt;
        }

        location.state.answer = val;
        location.state.type = 'assessment';
        dispatch(takeAssessment(location.state, navigate));
        return;
      }

      if (testStatus === false) {
        // setTimeout(() => {
        // location.state.test_status = testStatus;
        location.state.answer = val;
        // deprecated
        // dispatch(updateSession({ ...session, sessionFlag: false }));
        location.state.type = 'assessment';
        dispatch(takeAssessment(location.state, navigate));
        // navigate('/consent-form', {
        //   state: location?.state,
        // });
        return;
        // }, 2000);
      }

      const questionIndex: any = [];
      questions?.map((d: any, i: any) => {
        if (d.type == 'question' && d.answer == '') {
          questionIndex.push(i + 1);
        }
      });

      if (questionIndex?.length > 0) {
        Swal.fire({
          title: `Missing Responses to question(s): ${questionIndex.join(',')}`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Return to Assessment',
          confirmButtonText: 'Submit Incomplete Assessment',
          showCloseButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            location.state.submittedAt = utcSubmittedAt;
            location.state.answer = val;
            setButton(true);
            // deprecated
            // dispatch(updateSession({ ...session, sessionFlag: false }));
            location.state.type = 'assessment';
            location.state.test_assessment_ids =
              selectedData?.data?.test_assessment_ids;
            dispatch(takeAssessment(location.state, navigate));
          } else if (result.isDenied) {
            //User clicked "No"
            setSubmitted(false);
          } else if (result.isDismissed) {
            //User dismissed the popup
            setSubmitted(false);
          }
        });
      } else {
        location.state.submittedAt = utcSubmittedAt;
        location.state.answer = val;
        setButton(true);
        // deprecated
        // dispatch(updateSession({ ...session, sessionFlag: false }));
        location.state.type = 'assessment';
        dispatch(takeAssessment(location.state, navigate));
      }
    }
  }, [val]);

  const openImageInNewTab = () => {
    // Create an HTML document string with the image embedded
    const htmlContent = `
      <html>
        <head>
          <title>Reference Image</title>
          <style>
            body { 
              display: flex; 
              justify-content: center; 
              align-items: center; 
              height: 100vh; 
              margin: 0; 
              background-color: #f0f0f0;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          </style>
        </head>
        <body>
          <img src="${val.reference_image}" alt="Reference Image" />
        </body>
      </html>
    `;

    // Create a new Blob object using the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Open the new tab with the Blob URL
    const newTab = window.open(url, '_blank');

    // Check if the new tab opened successfully
    if (!newTab) {
      console.error('Failed to open new tab.');
    }
  };

  const openImageAdaptiveInNewTab = () => {
    // Create an HTML document string with the image embedded
    const htmlContent = `
      <html>
        <head>
          <title>Reference Image</title>
          <style>
            body { 
              display: flex; 
              justify-content: center; 
              align-items: center; 
              height: 100vh; 
              margin: 0; 
              background-color: #f0f0f0;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          </style>
        </head>
        <body>
          <img src="${refImage}" alt="Reference Image" />
        </body>
      </html>
    `;

    // Create a new Blob object using the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Open the new tab with the Blob URL
    const newTab = window.open(url, '_blank');

    // Check if the new tab opened successfully
    if (!newTab) {
      console.error('Failed to open new tab.');
    }
  };

  useEffect(() => {
    const postAnswers = (val: any) => {
      location.state.test_status = false;

      if (location?.state?.assessment_type === 'adaptive') {
        location.state.answer = val;
        location.state.type = 'assessment';

        dispatch(takeAssessment(location.state, () => {}, false, true));
        return;
      }

      location.state.answer = val;
      location.state.type = 'assessment';

      dispatch(takeAssessment(location.state, navigate, false, true));
      return;
    };

    if (
      location?.state?.assessment_type === 'static' &&
      activeStep > milestone
    ) {
      if (isInitialRender.current) {
        setMilestone(activeStep + 9);
        return;
      }
      postAnswers(val);
      setMilestone(activeStep + 9); // Set the next multiple of 10 as the new milestone
    }
  }, [activeStep, milestone]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {(questions?.length > 0 ? questions.length : 0) > 0 ? (
          <Box
            sx={{
              maxWidth: 900,
              flexGrow: 1,
            }}
          >
            <h2
              style={{
                fontSize: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {val?.public_name}
            </h2>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px',
              }}
            >
              <Button
                size='small'
                variant='contained'
                onClick={handleBack}
                disabled={
                  location?.state?.assessment_type === 'adaptive'
                    ? true
                    : activeStep === 0
                }
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
              {location?.state?.assessment_type === 'adaptive' ? (
                <div>
                  <b>
                    {activeStep + 1}
                    {/* <span className="text-green-600 font-bold">
        {currentQuestion?.duration}
        {questions[activeStep]?.duration}
      </span> */}
                  </b>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {' '}
                  {/* Added alignItems: 'center' */}
                  <Select
                    size='small'
                    value={activeStep}
                    onChange={(e: any) => {
                      isInitialRender.current = false;
                      updateDuration(e.target.value);
                      setActiveStep(e.target.value);
                    }}
                  >
                    {[
                      ...Array(
                        questions?.length > 0 ? questions.length : 0
                      ).keys(),
                    ].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

              <Button
                sx={{ marginRight: 2 }}
                size='small'
                variant='contained'
                onClick={handleNext}
                disabled={!questions[activeStep]}
                style={
                  location?.state?.assessment_type === 'adaptive' &&
                  questions?.length > 0
                    ? {
                        display: studentAssessmentData?.answer?.answer?.[
                          activeStep
                        ]?.lastItem
                          ? 'none'
                          : 'block',
                      }
                    : activeStep ===
                        (questions?.length > 0 ? questions.length : 0) - 1 &&
                      (location?.state?.assessment_type === 'static' ||
                        location?.state?.assessment_type === null)
                    ? { display: 'none' }
                    : {}
                }
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
              {/*Submit Button Up*/}

              <Button
                style={
                  (location?.state?.assessment_type === 'adaptive' &&
                    studentAssessmentData?.answer?.answer?.[activeStep]
                      ?.lastItem) ||
                  (location?.state?.assessment_type !== 'adaptive' &&
                    questions?.length - 1 === activeStep)
                    ? {}
                    : { display: 'none' }
                }
                variant='contained'
                disabled={button}
                onClick={() => handleSubmit(true)}
              >
                Complete Section{' '}
              </Button>
            </div>
            <Paper
              variant='outlined'
              // sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              {questions[activeStep] ? (
                <Box
                  sx={{
                    minHeight: 400,
                    height: 'auto',
                    maxWidth: 900,
                    width: '100%',
                    p: 1,
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                      {val.reference_image &&
                        location?.state?.assessment_type === 'static' && (
                          <>
                            <div>
                              <span>{val.image_description}</span>

                              <Tooltip title='View image'>
                                <IconButton onClick={openImageInNewTab}>
                                  <PhotoIcon className='text-edit-icon' />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </>
                        )}{' '}
                      {refImage &&
                        location?.state?.assessment_type === 'adaptive' && (
                          <>
                            <div>
                              <span>{imageDescription}</span>
                              <Tooltip title='View image'>
                                <IconButton onClick={openImageAdaptiveInNewTab}>
                                  <PhotoIcon className='text-edit-icon' />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </>
                        )}
                    </div>

                    <Grid
                      container
                      // spacing={1}
                      // style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={activeStep + 1 === 1 ? {} : { display: 'none' }}
                      >
                        {location?.state?.assessment_type === 'adaptive' ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                assessmentStatusData?.instruction?.replace(
                                  /\\/g,
                                  ''
                                )
                                  ? `<div class="sun-editor-editable" style="padding-top: 0px;
                              padding-bottom: 0px; max-width: 918px;">${assessmentStatusData?.instruction?.replace(
                                /\\/g,
                                ''
                              )}</div>`
                                  : '',
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: val?.question_data?.instruction?.replace(
                                /\\/g,
                                ''
                              )
                                ? `<div class="sun-editor-editable" style="padding-top: 0px;
                              padding-bottom: 0px; max-width: 918px;">${val?.question_data?.instruction?.replace(
                                /\\/g,
                                ''
                              )}</div>`
                                : '',
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<div class="sun-editor-editable" style="padding-top: 0px;
                          padding-bottom: 0px; max-width: 918px;">${framingCondition(
                            questions[activeStep]?.framing
                          )}</div>`,
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                          />
                        </div>
                      </Grid>

                      <Grid style={{ marginBottom: '5px' }} item xs={2} sm={2}>
                        {location?.state?.assessment_type === 'adaptive' ? (
                          <b>{activeStep + 1}</b>
                        ) : (
                          <b style={{ whiteSpace: 'nowrap' }}>
                            {questions[activeStep]?.questionLabel}
                          </b>
                        )}

                        {/* <b>{questions[activeStep]?.questionLabel}</b>
                            <b>{activeStep + 1} </b> */}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ display: 'flex', flexFlow: 'wrap' }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<div class="sun-editor-editable" style="padding-top: 0px;
        padding-bottom: 0px; max-width: 918px;">${questions[
          activeStep
        ]?.questionContent?.replace(
          location?.state?.assessment_type === 'adaptive'
            ? /(<span class="se-custom-tag">)\[Item-(\d+)\]/g
            : /(<span class="se-custom-tag">)\[Question (\d+)\]/g,
          (match: any, p1: any, p2: any, p3: any) => {
            // console.log('checking');
            if (location?.state?.assessment_type === 'adaptive') {
              const data = val.question_data.data.find(
                (d: any) => d.item_id == p2
              );

              if (data.answer == '-99') {
                return '[response from prior question]';
              }

              switch (data?.responseType) {
                case 'Single':
                  let optionContent =
                    data?.responseOptions[data.answer]?.optionContent;
                  return optionContent
                    ? `${p1}${optionContent.replace(/<\/?p>/g, '')}`
                    : '[response from prior question]';
                case 'Multiple':
                  let answerKeys = Object.entries(data.answer)
                    .filter(([key, value]) => value === true)
                    .map(([key, value]) => {
                      let optionContent =
                        data?.responseOptions[key]?.optionContent;
                      return optionContent
                        ? optionContent.replace(/<\/?p>/g, '')
                        : '[response from prior question]';
                    });

                  if (answerKeys.length > 1) {
                    let lastKey = answerKeys.pop();
                    return `${answerKeys.join(', ')} and ${lastKey}`;
                  } else {
                    return answerKeys.join(', ');
                  }
                default:
                  return data?.answer
                    ? data.answer
                    : '[response from prior question]';
              }
            } else {
              switch (questions[parseInt(p2) - 1]?.responseType) {
                case 'Single':
                  let optionContent =
                    questions[parseInt(p2) - 1]?.responseOptions[
                      questions[parseInt(p2) - 1]?.answer
                    ]?.optionContent;
                  return optionContent
                    ? `${p1}${optionContent.replace(/<\/?p>/g, '')}`
                    : '[response from prior question]';
                case 'Multiple':
                  let answerKeys = Object.entries(
                    questions[parseInt(p2) - 1]?.answer
                  )
                    .filter(([key, value]) => value === true)
                    .map(([key, value]) => {
                      let optionContent =
                        questions[parseInt(p2) - 1]?.responseOptions[key]
                          ?.optionContent;
                      return optionContent
                        ? optionContent.replace(/<\/?p>/g, '')
                        : '[response from prior question]';
                    });

                  if (answerKeys.length > 1) {
                    let lastKey = answerKeys.pop();
                    return `${answerKeys.join(', ')} and ${lastKey}`;
                  } else {
                    return answerKeys.join(', ');
                  }
                default:
                  return questions[parseInt(p2) - 1]?.answer
                    ? `${p1}${questions[parseInt(p2) - 1]?.answer}`
                    : '[response from prior question]';
              }
            }
          }
        )}</div>`,
                          }}
                          style={{ whiteSpace: 'pre-wrap' }}
                        />
                      </Grid>

                      {questions[activeStep]?.responseType === 'Single' ? (
                        <Grid item xs={12} sm={12}>
                          <FormControl
                            style={{
                              paddingLeft: '21px',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <RadioGroup
                              defaultValue={
                                questions[activeStep]?.answer
                                  ? questions[activeStep]?.answer
                                  : ''
                              }
                              // defaultChecked={gender.value}
                              name='answer'
                              value={
                                questions[activeStep]?.answer
                                  ? questions[activeStep]?.answer
                                  : ''
                              }
                              onChange={(e) => handleRadioChange(e, activeStep)}
                              style={
                                questions[activeStep].optionOrientation ===
                                'Vertical'
                                  ? {
                                      display: 'flex',
                                      paddingLeft: '13px',
                                      // marginTop: '10px',
                                      // marginBottom: '10px',
                                    }
                                  : questions[activeStep].optionOrientation ===
                                    'Horizontal'
                                  ? {
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                    }
                                  : { display: 'flex', flexDirection: 'row' }
                              }
                            >
                              {questions[activeStep]?.responseOptions.map(
                                (item: any, index: any) => (
                                  <label className='flex'>
                                    <FormControlLabel
                                      style={
                                        {
                                          // marginTop: '10px',
                                          // marginBottom: '10px',
                                        }
                                      }
                                      value={index}
                                      control={<Radio size='small' />}
                                      label={
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: `<div class="sun-editor-editable" style="padding-top: 9px;
                                      padding-bottom: 1px; max-width:918px">${item?.optionContent?.replace(
                                        /\\/g,
                                        ''
                                      )}</div>`,
                                          }}
                                          style={{ whiteSpace: 'pre-wrap' }}
                                        />
                                        // <div></div>
                                      }
                                    />
                                    <TextField
                                      {...(item?.optionType === 'other'
                                        ? {
                                            value: questions[activeStep]
                                              ?.otherText
                                              ? questions[activeStep]?.otherText
                                              : '',
                                          }
                                        : { style: { display: 'none' } })}
                                      name='answer'
                                      onChange={(e: any) =>
                                        onInputChange(e, activeStep, 'Single')
                                      }
                                      disabled={
                                        questions[
                                          activeStep
                                        ]?.responseOptions?.findIndex(
                                          (option: any) =>
                                            option.optionType == 'other'
                                        ) != questions[activeStep]?.answer
                                      }
                                      // style={{ marginLeft: '20px' }}
                                      // placeholder='other'
                                      size='small'
                                    />
                                  </label>
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Multiple' ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={
                            questions[activeStep]?.optionOrientation ===
                            'Horizontal'
                              ? {
                                  display: 'flex',
                                }
                              : {}
                          }
                        >
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <label
                                key={index}
                                style={
                                  questions[activeStep]?.optionOrientation ===
                                  'Vertical'
                                    ? {
                                        display: 'flex',
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                    : questions[activeStep]
                                        ?.optionOrientation === 'Horizontal'
                                    ? {
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                      }
                                    : {
                                        display: 'flex',
                                        // marginTop: '10px',
                                        // marginBottom: '10px',
                                      }
                                }
                              >
                                <Checkbox
                                  // value={item.value}
                                  id={index}
                                  size='small'
                                  name={index}
                                  checked={
                                    questions[activeStep]?.answer[index] ||
                                    false
                                  }
                                  onChange={(e) =>
                                    onCheckboxChange(e, activeStep)
                                  }
                                />
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `<div class="sun-editor-editable" style="padding-top: 9px;
                                padding-bottom: 1px; max-width:918px">${questions[
                                  activeStep
                                ]?.responseOptions[
                                  index
                                ].optionContent?.replace(/\\/g, '')}</div>`,
                                  }}
                                ></div>

                                <TextField
                                  {...(item?.optionType === 'other'
                                    ? {
                                        value: questions[activeStep]?.otherText
                                          ? questions[activeStep]?.otherText
                                          : '',
                                      }
                                    : { style: { display: 'none' } })}
                                  name='answer'
                                  onChange={(e: any) =>
                                    onInputChange(e, activeStep, 'Multiple')
                                  }
                                  disabled={(() => {
                                    const otherOptionIndex = questions[
                                      activeStep
                                    ]?.responseOptions?.findIndex(
                                      (option: any) =>
                                        option.optionType == 'other'
                                    );

                                    return !(
                                      questions[activeStep]?.answer &&
                                      Object.hasOwn(
                                        questions[activeStep]?.answer,
                                        otherOptionIndex
                                      ) &&
                                      questions[activeStep]?.answer[
                                        otherOptionIndex
                                      ] == true
                                    );
                                  })()}
                                  // style={{ marginLeft: '20px' }}
                                  // placeholder='other'
                                  size='small'
                                />
                              </label>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Open' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => {
                              const itemString = JSON.stringify(item);
                              const hash = CryptoJS.SHA256(
                                itemString + activeStep + index
                              ).toString();
                              return (
                                <div key={hash}>
                                  <TextField
                                    fullWidth
                                    multiline
                                    name='answer'
                                    label='Response Input:'
                                    defaultValue={
                                      questions[activeStep]?.answer || ''
                                    }
                                    // value={data?.answer || ''}
                                    ref={(input: any) => {
                                      if (input) {
                                        input.focus();
                                      }
                                    }}
                                    onChange={(e: any) =>
                                      onInputChange(e, activeStep)
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Text' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => {
                              const itemString = JSON.stringify(item);
                              const hash = CryptoJS.SHA256(
                                itemString + activeStep + index
                              ).toString();
                              return (
                                <div key={hash}>
                                  <TextField
                                    //  fullWidth
                                    className='w-full'
                                    name='answer'
                                    label='Response Input:'
                                    defaultValue={
                                      questions[activeStep]?.answer || ''
                                    }
                                    // value={data?.answer || ''}
                                    onChange={(e: any) =>
                                      onInputChange(e, activeStep)
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}

                      {questions[activeStep]?.responseType === 'Number' ? (
                        <Grid item xs={12} sm={12}>
                          {questions[activeStep]?.responseOptions.map(
                            (item: any, index: any) => (
                              <div key={questions[activeStep].index}>
                                <TextField
                                  type='number'
                                  name='answer'
                                  label='Response Input:'
                                  defaultValue={
                                    questions[activeStep]?.answer
                                      ? Number(questions[activeStep]?.answer)
                                      : ''
                                  }
                                  // value={data?.answer || ''}
                                  onChange={(e: any) =>
                                    onInputChange(e, activeStep)
                                  }
                                />
                              </div>
                            )
                          )}
                        </Grid>
                      ) : (
                        ''
                      )}
                      {questions[activeStep]?.responseType === 'None' ? (
                        <Grid item xs={12} sm={12}>
                          <Button
                            variant='contained'
                            className='col-span-2'
                            style={{
                              marginTop: '10px',
                              marginLeft: '25px',
                              backgroundColor: '#335D88',
                            }}
                            onClick={handleNext}
                          >
                            I have finished reading
                          </Button>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      <Button
                        sx={{ marginRight: 2 }}
                        // variant="outlined"
                        onClick={() => {
                          handleSubmit(false);
                        }}
                      >
                        Save And Complete Later
                      </Button>
                      <Button
                        sx={{ marginRight: 2 }}
                        size='small'
                        variant='contained'
                        onClick={handleNext}
                        style={
                          location?.state?.assessment_type === 'adaptive' &&
                          questions?.length > 0
                            ? {
                                display: studentAssessmentData?.answer
                                  ?.answer?.[activeStep]?.lastItem
                                  ? 'none'
                                  : 'block',
                              }
                            : activeStep ===
                                (questions?.length > 0 ? questions.length : 0) -
                                  1 &&
                              (location?.state?.assessment_type === 'static' ||
                                location?.state?.assessment_type === null)
                            ? { display: 'none' }
                            : {}
                        }
                        // disabled={
                        //   location?.state?.assessment_type === 'adaptive' &&
                        //   questions?.[activeStep]?.answer
                        //     ? studentAssessmentData?.answer?.answer?.[
                        //         activeStep
                        //       ]?.lastItem
                        //     : activeStep ===
                        //       (questions?.length > 0 ? questions.length : 0) - 1
                        // }
                      >
                        Next
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                      {/*Submit Button Down*/}
                      <Button
                        style={
                          (location?.state?.assessment_type === 'adaptive' &&
                            studentAssessmentData?.answer?.answer?.[activeStep]
                              ?.lastItem) ||
                          (location?.state?.assessment_type !== 'adaptive' &&
                            questions?.length - 1 === activeStep)
                            ? {}
                            : { display: 'none' }
                        }
                        variant='contained'
                        disabled={button}
                        onClick={() => handleSubmit(true)}
                      >
                        Complete Section{' '}
                      </Button>
                      {/* <div>
                      {JSON.stringify(
                        studentAssessmentData?.answer?.answer[activeStep]
                      )}
                    </div> */}
                    </div>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    minHeight: 400,
                    height: 'auto',
                    maxWidth: 900,
                    width: '100%',
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack
                    spacing={1}
                    alignItems='center'
                    justifyContent='center'
                  >
                    <CircularProgress />
                    <Typography variant='body1'>
                      Loading next question...
                    </Typography>
                  </Stack>
                </Box>
              )}
            </Paper>
          </Box>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TakeAssessmentQuestion;
